import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "@reach/router";

const ProtectedContext = React.createContext({});
const { Provider, Consumer } = ProtectedContext;

const rules = (type) => ({
  admin: type === "administrator",
  recruitment: ["administrator", "moderator", "recruiter"].includes(type),
  manager: type === "manager",
  recruiter: type === "recruiter",
  contractor: ["employee", "subcontractor", "payroll"].includes(type),
  employee: type === "employee",
  subcontractor: type === "subcontractor",
  moderator: ["administrator", "moderator"].includes(type),
  managerGroup: ["administrator", "moderator", "manager"].includes(type),
  editor: [
    "administrator",
    "employee",
    "payroll",
    "subcontractor",
    "moderator",
  ].includes(type),
  editorltd: ["administrator", "subcontractor", "moderator"].includes(type),
});

export { ProtectedContext, Provider };
export const ProtectedRoute = (props) => (
  <Consumer>
    {(value) =>
      rules(value.type)[props.rule] ? (
        <props.render {...props} />
      ) : (
        <Redirect to="/flextime" noThrow />
      )
    }
  </Consumer>
);

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  render: PropTypes.func,
  rule: PropTypes.string.isRequired,
};

export const ProtectedElement = (props) => (
  <Consumer>
    {(value) =>
      rules(value.type)[props.rule] ? props.children : props.fallback || null
    }
  </Consumer>
);

ProtectedElement.propTypes = {
  children: PropTypes.any,
  fallback: PropTypes.any,
  rule: PropTypes.string.isRequired,
};

export const IsLoggedIn = (props) => (
  <Consumer>
    {(value) =>
      value.type ? <props.render {...props} /> : <props.fallback {...props} />
    }
  </Consumer>
);

IsLoggedIn.propTypes = {
  render: PropTypes.any.isRequired,
  fallback: PropTypes.any.isRequired,
};
