import React from "react";
import styled, { css, keyframes } from "styled-components";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import vars from "../lib/styled";
import withMedia from "./withMedia";
import { ContainerLarge } from "./Container";

const fade = keyframes`
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
`;

const MenuBar = styled.nav`
  background-color: #1f1f1f;
  box-sizing: border-box;
  width: 100%;

  ${withMedia(
    "tablet",
    css`
      display: none;
    `
  )}
`;

const MenuBarList = styled(ContainerLarge)`
  display: flex;
  margin: 0 auto;

  a {
    display: inline-block;
    line-height: 4;
    margin-right: 2rem;
  }
`;

const MenuDropdown = styled.div`
  position: relative;
  z-index: 1;

  &:hover,
  &:focus-within {
    div {
      display: block;
    }
  }

  div {
    animation: ${fade} 500ms;
    display: none;
    position: absolute;
    top: 100%;
    width: 100%;

    a {
      &:nth-child(even) {
        background-color: #f55052;
      }
      &:nth-child(odd) {
        background-color: ${vars.primary};
      }
      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:hover,
      &:focus {
        background-color: ${vars.dark};
      }

      background: red;
      display: block;
      font-size: small;
      line-height: 1.2;
      padding: 0.75rem 0.25rem;
      text-align: center;
      transition: background-color 500ms;
    }
  }
`;

const Menu = (props) => (
  <MenuBar>
    <MenuBarList>
      {props.menu.map((obj, i) => {
        if (!obj.children)
          return (
            <Link key={i} to={obj.link}>
              {obj.name}
            </Link>
          );

        return (
          <MenuDropdown key={i}>
            <Link key={i} to={obj.link}>
              {obj.name}
            </Link>
            <div>
              {obj.children.map(({ node }, i) => (
                <Link
                  to={node.top ? `/${node.url}` : `${obj.link}/${node.url}`}
                  key={i}
                >
                  {node.title}
                </Link>
              ))}
            </div>
          </MenuDropdown>
        );
      })}
    </MenuBarList>
  </MenuBar>
);

Menu.propTypes = {
  menu: PropTypes.array.isRequired,
};

export default Menu;
