import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const App = props => (<div>
    <NavBar />
    {props.children}
    <Footer />
</div>);

export default App;