import styled, { css } from 'styled-components';
import withMedia from './withMedia';

const Container = styled.section`
    box-sizing: border-box;
    padding: 0 2rem;
    position: relative;
    margin: 1rem auto;
    max-width: 100%;
    width: 650px;

    ${withMedia('phone', css`
        padding: 0 1rem;
    `)}
`;

export default Container;

export const ContainerLarge = styled(Container)`
    width: 1450px;

    ${withMedia('laptop', css`
        width: 950px;
    `)}
`;

export const ContainerMedium = styled(Container)`
    width: 1250px;

    ${withMedia('laptop', css`
        width: 850px;
    `)}
`;