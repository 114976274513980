import React from 'react';
import styled, { css } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import withMedia from './withMedia';

const Phone = styled.a`
    display: block;
    margin-bottom: 0.5rem;
    margin-right: 1rem;

    span { font-weight: bold }
    &:last-of-type { margin-bottom: 1rem }

    ${withMedia('tablet', css`
        display: inline-block;
        margin: 0 0.5rem !important;
    `)}
`;

const Numbers = () => (
    <StaticQuery
        query={graphql`
            query {
                site {
                    siteMetadata {
                        local 
                        tollFree 
                    }
                }
            }`
        }

        render={({ site }) => (<>
            <Phone href={`tel:${site.siteMetadata.local}`}>Local: <span>{site.siteMetadata.local}</span></Phone>
            <Phone href={`tel:${site.siteMetadata.tollFree}`}>Toll-Free: <span>{site.siteMetadata.tollFree}</span></Phone>
        </>)}
    />
);

export default Numbers;
