import React from 'react';
import styled, { css } from 'styled-components';
import { Link, StaticQuery, graphql } from 'gatsby';
import Button, { ExternalLink } from './Button';
import { IsLoggedIn } from './Protected';
import { ContainerLarge } from './Container';
import Offcanvas from './Offcanvas';
import Numbers from './Numbers';
import Logout from '../pages/logout';
import Menu from './Menu';
import withMedia from './withMedia';
import vars from '../lib/styled';

const Wrapper = styled.div`
    background-color: ${vars.primary};
    color: ${vars.light};

    a { color: #FFF }
`;

const Container = styled(ContainerLarge)`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 2rem;

    ${withMedia('tablet', css`
        display: block;
        padding: 2rem 1rem;
    `)}
`;

const ActionBar = styled.div`
    text-align: right;

    ${withMedia('tablet', css`
        text-align: center;
    `)}
`;

const ActionBarDesktop = styled.div`
    display: block;

    ${withMedia('tablet', css`
        display: none;
    `)}
`;

const LogoContainer = styled.div`
    align-items: center;
    display: flex;
    
    ${withMedia('tablet', css`
        display: block;

        a { 
            display: block;
            text-align: center;
        }

        img { width: 140px }
    `)}
`;

const Tagline = styled.div`
    font-size:0.833em;
    margin-left: 1rem;
    width: 300px;

    ${withMedia('tablet', css`
        margin: 2rem auto;
        text-align: center;
        width: 90%;
    `)}
`;

const Toolbar = styled.div`
    background-color: rgba(255,255,255,0.22);
    display: none;
    padding: 1rem;
    text-align: center;
    width: 100%;

    ${withMedia('tablet', css`
        display: block;
    `)}
`;

const NavBar = props => (
    <StaticQuery
        query={graphql`
            query {
                site {
                    siteMetadata {
                        tagline
                    }
                }

                # for nested children of candidate services
                pages: allContentfulPage(filter: {contentfulparent: { url: {eq: "candidate-services"}}}){
                    edges {
                        node {
                            title: heading
                            url 
                        }
                    }
                }
            }`
        }

        render={data => {

            let menu = [
                { name: 'Home', link: '/' },
                { name: 'Client Services', link: '/client-services', children: [
                    { node: { title: 'Find Talent', top: true, url: 'talent' } }
                ], 
            },
                {
                    name: 'Candidate Services', link: '/candidate-services', children: [
                        { node: { title: 'Submit a Resume', top: true, url: 'resume' } },
                        ...data.pages.edges
                    ]
                },
                { name: 'About', link: '/about' },
                { name: 'Contact', link: '/contact' }
            ];

            return (
                <Wrapper>
                    <Toolbar>
                        <Numbers />
                    </Toolbar>
                    <Container>
                        <LogoContainer>
                            <Link to="/">
                                <img src="/logo.png" alt="FlexStaf IT Logo" />
                            </Link>

                            <Tagline>
                                {data.site.siteMetadata.tagline}
                            </Tagline>
                        </LogoContainer>

                        <ActionBar>
                            <ActionBarDesktop>
                                <Numbers />
                            </ActionBarDesktop>
                            <div>
                            <ExternalLink href="https://www.careers-page.com/flexstaf-it">Job Search</ExternalLink>
                                {/* <ExternalLink href="https://jobs-flexstaf-it.netlify.app/">Job Search</ExternalLink> */}
                                <IsLoggedIn
                                    render={() => <Logout />}
                                    fallback={() => <Button to="/flextime">FlexTime</Button>}
                                />
                            </div>
                        </ActionBar>
                    </Container>

                    <Offcanvas menu={menu} />
                    <Menu menu={menu} />

                </Wrapper>);
        }}
    />
);


export default NavBar;