import React from 'react';
import PropTypes from 'prop-types';
import sprite from '../../static/sprite.svg';

const Icon = ({ name, ...rest }) => (<svg {...rest}>
    <use xlinkHref={`${sprite}#${name}`}></use>
</svg>);

Icon.propTypes = {
    name: PropTypes.string.isRequired
};

export default Icon;