import styled, { css } from 'styled-components';
import withMedia from './withMedia';

export default styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -1rem;

    ${withMedia('phone', css`
        display: block;
    `)}
`;

const Box = styled.div`
    box-sizing: border-box;
    padding: 1rem;
    
    ${withMedia('phone', css`
        padding: 0;
    `)}
`;

export const Auto = styled(Box)`
    flex-grow: 1;
    width: auto;
`;

export const Full = styled(Box)`
    width: 100%;
    
    ${withMedia('tablet', css`
        width: 100%;
    `)}
`;

export const Third = styled(Box)`
    width: 33%;
    
    ${withMedia('tablet', css`
        width: 100%;
    `)}
`;

export const TwoThirds = styled(Box)`
    width: 66%;

    ${withMedia('tablet', css`
        width: 100%;
    `)}
`;

export const Half = styled(Box)`
    width: 50%;
    
    ${withMedia('phone', css`
        width: 100%;
    `)}
`;