import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import styled from "styled-components";
import moment from "moment";
import { StaticQuery, graphql } from "gatsby";
import { ContainerLarge } from "./Container";
import Grid, { Third } from "./Grid";
import Button from "./Button";

const FooterWrapper = styled.footer`
  background-color: #1f1f1f;
  color: #fff;
  padding: 1rem 1rem 1rem 1rem;
  width: 100%;
`;

export const NoStylelist = styled.ul`
  color: #fff;
  list-style: none;
  margin-bottom: 2rem;
  padding: 0;

  li {
    margin: 0.5rem 0;
  }

  a {
    color: #fff;
    font-weight: bold;
  }
`;

const Footer = () => (
  <FooterWrapper>
    <ContainerLarge>
      <Grid>
        <Third>
          <h3>Where to Find Us</h3>
          <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2876.363754636444!2d-78.92078662426769!3d43.869014438446555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d51dfaa4d4b573%3A0x10ec7faba5caca95!2s105%20Consumers%20Dr%20n219%2C%20Whitby%2C%20ON%20L1N%201C4%2C%20Canada!5e0!3m2!1sen!2sus!4v1698903355049!5m2!1sen!2sus" 
          height="250">
          </iframe>
        </Third>

        <StaticQuery
          query={graphql`
            query {
              site {
                siteMetadata {
                  local
                  tollFree
                  email
                  fax
                  street
                  city
                  postal
                }
              }
            }
          `}
          render={({ site }) => (
            <Third>
              <h3>Contact Us</h3>
              <NoStylelist>
                <li>{site.siteMetadata.street}</li>
                <li>{site.siteMetadata.city}</li>
                <li>{site.siteMetadata.postal}</li>
              </NoStylelist>
              <NoStylelist>
                <li>
                  Toll-Free:{" "}
                  <a href={`tel:${site.siteMetadata.tollFree}`}>
                    {site.siteMetadata.tollFree}
                  </a>
                </li>
                <li>
                  Local:{" "}
                  <a href={`tel:${site.siteMetadata.local}`}>
                    {site.siteMetadata.local}
                  </a>
                </li>
                <li>
                  Fax:{" "}
                  <a href={`tel:${site.siteMetadata.fax}`}>
                    {site.siteMetadata.fax}
                  </a>
                </li>
                <li>
                  Email:{" "}
                  <a href={`mailTo:${site.siteMetadata.email}`}>
                    {site.siteMetadata.email}
                  </a>
                </li>
              </NoStylelist>
              <Button to="/flextime" style={{ color: "#FFF" }}>
                Recruiter Login
              </Button>
            </Third>
          )}
        />

        <Third style={{ overflow: "hidden", maxWidth: "100%" }}>
          <h3>Get Social</h3>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="FlexstafIT"
            options={{ height: 400 }}
          />
        </Third>
      </Grid>

      <small>
        Copyright &copy; {moment.utc().format("YYYY")} FlexStaf IT. All rights
        reserved.
      </small>
    </ContainerLarge>
  </FooterWrapper>
);

export default Footer;
