import React, { Component } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import posed from "react-pose";
import Icon from "./Icon";
import withMedia from "./withMedia";
import vars from "../lib/styled";

const Trigger = styled.button`
  background-color: ${vars.dark};
  border: 0;
  border-radius: 0;
  color: ${vars.light};
  display: none;
  line-height: 3rem;
  padding: 0;
  width: 100%;

  ${withMedia(
    "tablet",
    css`
      display: block;
    `
  )}

  svg {
    fill: ${vars.light};
    margin-left: 1rem;
    margin-bottom: -0.15rem;
    width: 1rem;
  }

  span,
  svg {
    height: 1rem;
  }
`;

const Close = styled(Trigger)`
  position: relative;
`;

const OffcanvasPanel = styled(
  posed.div({
    enter: {
      opacity: 1,
      delayChildren: 200,
      staggerChildren: 100,
      applyAtStart: {
        display: "block",
      },
    },
    exit: {
      opacity: 0,
      applyAtEnd: {
        display: "none",
      },
    },
  })
)`
  background-color: ${vars.primary};
  display: none;
  height: 100vh;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  a {
    display: block;
    font-size: 1.56em;
  }
`;

const OffcanvasList = styled.ul`
  list-style: none;
  margin: 3rem 0;
  padding: 0 2rem;
`;

const OffCanvasListItem = styled(
  posed.li({
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  })
)`
  margin-bottom: 2rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li a {
    opacity: 0.75;
    font-size: 1.133rem;
    margin: 1rem 0 0;
  }
`;

export default class Offcanvas extends Component {
  state = { open: false };

  static propTypes = {
    menu: PropTypes.array.isRequired,
  };

  toggle = () =>
    this.setState(({ open }) => ({
      open: !open,
    }));

  render = () => (
    <>
      <Trigger type="button" onClick={() => this.toggle()}>
        <span>Menu</span>
        <Icon name="Menu" />
      </Trigger>
      <OffcanvasPanel
        initialPose="exit"
        pose={this.state.open ? "enter" : "exit"}
      >
        <Close type="button" onClick={() => this.toggle()}>
          <span>Close</span>
        </Close>

        <OffcanvasList>
          {this.props.menu.map((obj, i) => (
            <OffCanvasListItem key={i}>
              <Link to={obj.link}>{obj.name}</Link>
              {obj.children && (
                <ul>
                  {obj.children.map(({ node }, i) => (
                    <li key={i}>
                      <Link
                        to={
                          node.top ? `/${node.url}` : `${obj.link}/${node.url}`
                        }
                      >
                        {node.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </OffCanvasListItem>
          ))}
        </OffcanvasList>
      </OffcanvasPanel>
    </>
  );
}
